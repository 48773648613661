import useEcho from '@/Hooks/useEcho'
import useNotifications from '@/Hooks/useNotifications'
import { router, usePage } from '@inertiajs/react'
import { useCallback, useEffect } from 'react'

export default function UserNotifications() {
  const { auth } = usePage().props
  const { showAlert } = useNotifications()

  const onOutBid = useCallback(
    (event) => {
      console.info('BidOutbid', { event })

      showAlert('warning', `¡Tu puja por el lote ${event.lot_code} fue superada!`, [
        [
          '<button class="btn btn-xs">Ver lote</button>',
          (instance, toast) => {
            instance.hide({}, toast)

            router.visit(
              route('auction.lot', {
                auction: event.auction_id,
                lot: event.lot_id,
              })
            )
          },
          false,
        ],
      ])
    },
    [showAlert]
  )

  const onLotAuctionEnded = useCallback(
    (event) => {
      console.info('LotAuctionEnded', { event })

      const isBuyer = event.buyer_id === auth.user.id

      const alertType = isBuyer ? 'success' : 'info'
      const alertMessage = isBuyer
        ? `¡Ganaste la puja por el lote ${event.lot_code}!`
        : `La puja por el lote ${event.lot_code} ha finalizado.`
      const buttons = isBuyer
        ? [
            [
              '<button class="btn btn-xs">Ver lote</button>',
              (instance, toast) => {
                instance.hide({}, toast)
                router.visit(
                  route('auction.lot', {
                    auction: event.auction_id,
                    lot: event.lot_id,
                  })
                )
              },
              false,
            ],
          ]
        : []

      showAlert(alertType, alertMessage, buttons)
    },
    [auth.user.id, showAlert]
  )

  const { connect, leave } = useEcho({
    channel: `users.${auth.user.id}`,
    events: {
      BidOutbid: onOutBid,
      LotAuctionEnded: onLotAuctionEnded,
    },
  })

  // Handle Echo connection
  useEffect(() => {
    connect()

    return () => {
      leave()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

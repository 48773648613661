import { immediateToast } from 'izitoast-react'
import { useCallback } from 'react'

export default function useNotifications() {
  /**
   * @param {string} type 'info' | 'error' | 'warning' | 'success' | 'question'
   * @param {string} message
   * @param {Array} buttons
   */
  const showAlert = useCallback((type, message, buttons = []) => {
    immediateToast(type, {
      message,
      position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
      buttons,
    })
  }, [])

  return {
    showAlert,
  }
}

import { FaWhatsapp } from 'react-icons/fa'

// Whatsapp floating button component using fabicons
const FloatingWhatsApp = () => {
  return (
    <div className="fixed bottom-0 right-0 mb-4 mr-4">
      <a
        href="https://wa.me/5491161520439"
        className="block rounded-full bg-green-500 py-2 px-2 font-bold text-white shadow-lg hover:bg-green-600"
        target="_blank"
        rel="noopener noreferrer"
        title="WhatsApp"
      >
        <FaWhatsapp className="h-8 w-8" />
      </a>
    </div>
  )
}

export default FloatingWhatsApp

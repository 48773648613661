import FloatingWhatsApp from '@/Components/Common/FloatingWhatsApp'
import Footer from '@/Components/Layout/Footer'
import Header from '@/Components/Layout/Header'
import UserNotifications from '@/Components/User/UserNotifications'
import { usePage } from '@inertiajs/react'
import { useEffect } from 'react'
import FlashMessages from '@/Components/Common/FlashMessages'
import MessageBanner from '@/Components/Common/MessageBanner'
import { useTracking } from 'react-tracking'

export default function Layout({ children }) {
  const page = usePage()
  const { props, url } = page
  const { Track } = useTracking(
    { page: url },
    {
      dispatch: ({ page, event, ...props }) => {
        console.debug('Tracking', { page, event, ...props })

        try {
          window.dataLayer.push({
            event,
            page,
            ...props,
          })
        } catch (error) {
          console.warn(error)
        }
      },
    }
  )

  useEffect(() => {
    console.info('Layout', { page })
  }, [page])

  return (
    <Track>
      <div className="mt-[var(--header-height)] flex flex-1 flex-col">
        <Header />
        <MessageBanner />
        <main className="flex-1">{children}</main>
        <Footer />
        <FloatingWhatsApp />
        {!!props.auth?.user && <UserNotifications />}
        <FlashMessages message={props.flash?.status} />
      </div>
    </Track>
  )
}

import { Link } from '@inertiajs/react'
import config from '@/Lib/config'

export default function Footer() {
  return (
    <footer className="bg-primary-900 pt-12 lg:pt-16">
      <div className="container mx-auto">
        <div className="footer">
          <div>
            <div className="text-center">
              <Link href="/" className="mb-2 inline-block">
                <img src="/img/logo/logo-inverted.svg" alt="logo" className="header-logo" />
              </Link>

              <div className="mb-8">LS Norte</div>

              <div className="-mx-3 flex items-center justify-center">
                {/*<a*/}
                {/*  href="https://www.facebook.com/Remateslasubasta"*/}
                {/*  target="_blank"*/}
                {/*  className="hover:text-gray-400 px-3"*/}
                {/*  rel="noreferrer"*/}
                {/*>*/}
                {/*  <svg width="10" height="18" viewBox="0 0 10 18" className="fill-current">*/}
                {/*    <path d="M9.00007 6.82105H7.50006H6.96434V6.27097V4.56571V4.01562H7.50006H8.62507C8.91971 4.01562 9.16078 3.79559 9.16078 3.46554V0.550085C9.16078 0.247538 8.9465 0 8.62507 0H6.66969C4.55361 0 3.08038 1.54024 3.08038 3.82309V6.21596V6.76605H2.54466H0.72322C0.348217 6.76605 0 7.06859 0 7.50866V9.48897C0 9.87402 0.294645 10.2316 0.72322 10.2316H2.49109H3.02681V10.7817V16.31C3.02681 16.6951 3.32145 17.0526 3.75003 17.0526H6.26791C6.42862 17.0526 6.56255 16.9701 6.66969 16.8601C6.77684 16.7501 6.8572 16.5576 6.8572 16.3925V10.8092V10.2591H7.4197H8.62507C8.97328 10.2591 9.24114 10.0391 9.29471 9.709V9.6815V9.65399L9.66972 7.7562C9.6965 7.56367 9.66972 7.34363 9.509 7.1236C9.45543 6.98608 9.21436 6.84856 9.00007 6.82105Z" />*/}
                {/*  </svg>*/}
                {/*</a>*/}
                <a
                  href={config.instagramUrl}
                  target="_blank"
                  className="hover:text-gray-400 px-3"
                  rel="noreferrer"
                  title="Visitanos en Instagram"
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" className="fill-current">
                    <path d="M8.91688 12.4995C10.6918 12.4995 12.1306 11.0911 12.1306 9.35385C12.1306 7.61655 10.6918 6.20819 8.91688 6.20819C7.14197 6.20819 5.70312 7.61655 5.70312 9.35385C5.70312 11.0911 7.14197 12.4995 8.91688 12.4995Z" />
                    <path d="M12.4078 0.947388H5.37075C2.57257 0.947388 0.300781 3.17104 0.300781 5.90993V12.7436C0.300781 15.5367 2.57257 17.7604 5.37075 17.7604H12.3524C15.2059 17.7604 17.4777 15.5367 17.4777 12.7978V5.90993C17.4777 3.17104 15.2059 0.947388 12.4078 0.947388ZM8.91696 13.4758C6.56206 13.4758 4.70584 11.6047 4.70584 9.35389C4.70584 7.10312 6.58976 5.23199 8.91696 5.23199C11.2165 5.23199 13.1004 7.10312 13.1004 9.35389C13.1004 11.6047 11.2442 13.4758 8.91696 13.4758ZM14.735 5.61164C14.4579 5.90993 14.0423 6.07264 13.5714 6.07264C13.1558 6.07264 12.7402 5.90993 12.4078 5.61164C12.103 5.31334 11.9368 4.9337 11.9368 4.47269C11.9368 4.01169 12.103 3.65916 12.4078 3.33375C12.7125 3.00834 13.1004 2.84563 13.5714 2.84563C13.9869 2.84563 14.4302 3.00834 14.735 3.30663C15.012 3.65916 15.2059 4.06593 15.2059 4.49981C15.1782 4.9337 15.012 5.31334 14.735 5.61164Z" />
                    <path d="M13.5985 3.82184C13.2383 3.82184 12.9336 4.12013 12.9336 4.47266C12.9336 4.82519 13.2383 5.12349 13.5985 5.12349C13.9587 5.12349 14.2634 4.82519 14.2634 4.47266C14.2634 4.12013 13.9864 3.82184 13.5985 3.82184Z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div>
            <span className="footer-title">Enlaces</span>
            <a href="#home" className="link-hover link mb-2 inline-block leading-loose">
              Inicio
            </a>
            <a href="#services" className="link-hover link mb-2 inline-block leading-loose">
              Servicios
            </a>
            <a href="#about" className="link-hover link mb-2 inline-block leading-loose">
              Nosotros
            </a>
            <a
              href={config.whatsappUrl}
              target="_blank"
              className="link-hover link inline-block leading-loose"
              rel="noreferrer"
            >
              Escribinos
            </a>
          </div>
          <div>
            <span className="footer-title">Contacto</span>
            <a
              href="https://maps.app.goo.gl/HsUZ4z4kaCrUPHrC8"
              target="_blank"
              className="link-hover link mb-2 flex items-center space-x-2 leading-loose"
              rel="noreferrer"
            >
              <i className="mdi mdi-map-marker text-2xl"></i>
              <span>Maquinista Carregal 4285, Munro, Buenos Aires</span>
            </a>
            {/*<a*/}
            {/*  href="tel:4545 3933"*/}
            {/*  target="_blank"*/}
            {/*  className="link-hover link mb-2 flex items-center space-x-2 leading-loose"*/}
            {/*  rel="noreferrer"*/}
            {/*>*/}
            {/*  <i className="mdi mdi-phone text-2xl"></i>*/}
            {/*  <span>4545 3933</span>*/}
            {/*</a>*/}
            <a
              href={`mailto:${config.email}`}
              target="_blank"
              className="link-hover link mb-2 flex items-center space-x-2 leading-loose"
              rel="noreferrer"
            >
              <i className="mdi mdi-email text-2xl"></i>
              <span>{config.email}</span>
            </a>
            <a
              href={config.whatsappUrl}
              target="_blank"
              className="link-hover link mb-2 flex items-center space-x-2 leading-loose"
              rel="noreferrer"
            >
              <i className="mdi mdi-whatsapp text-2xl"></i>
              <span>11 7168 3982</span>
            </a>
            <div>
              <span className="mb-2 flex items-center space-x-2 leading-loose">
                <i className="mdi mdi-calendar-clock text-2xl"></i>
                <span>Lun. a Vie. 8:30 a 13hs y 13:30 a 16:30hs | Sáb. solo los dos primeros del mes 8:30 a 13hs</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 border-t border-t-primary border-opacity-40 py-4 lg:mt-16">
        <div className="container mx-auto">
          <div className="text-center text-xs">© LS Norte 2024 | Todos los derechos reservados</div>
        </div>
      </div>
    </footer>
  )
}

import { useCallback, useContext } from 'react'
import { EchoContext } from '@/Context/EchoContext'

const openedChannels = new Map()

/**
 * Channel events.
 *
 * @example {"channelName": { "eventName": [callback()] }}
 * @type {Map<any, any>}
 */
const channelEvents = new Map()

/**
 *
 * @param {string} channel
 * @param {boolean} isPrivate
 * @returns {string|*}
 */
const getChannelName = (channel, isPrivate) => (isPrivate ? `private-${channel}` : channel)

/**
 *
 * @param {Echo} echo
 * @param {string} channel
 * @param {boolean} isPrivate
 * @returns {Channel|*}
 */
const getEchoChannel = (echo, channel, isPrivate) => {
  const echoChannelName = getChannelName(channel, isPrivate)

  if (openedChannels.has(echoChannelName)) {
    return openedChannels.get(echoChannelName)
  }

  const echoChannel = isPrivate ? echo.private(channel) : echo.channel(channel)

  // add the channel to the opened channels
  openedChannels.set(echoChannelName, echoChannel)

  return echoChannel
}

/**
 *
 * @param {Echo} echo
 * @param {string} channel
 * @param {boolean} isPrivate
 */
const leaveChannel = (echo, channel, isPrivate) => {
  const echoChannel = getEchoChannel(echo, channel, isPrivate)
  const echoChannelName = echoChannel.name

  echo.leave(channel)
  openedChannels.delete(echoChannelName)
}

/**
 *
 * @param {Echo} echo
 * @param {string} channel
 * @param {Object<string, function>} events
 * @param {boolean} isPrivate
 */
const addChannelEvents = (echo, channel, events, isPrivate) => {
  const echoChannel = getEchoChannel(echo, channel, isPrivate)

  console.debug('Listening for events', { channel, events, isPrivate })

  const echoChannelName = echoChannel.name

  const currentEvents = channelEvents.get(echoChannelName) ?? new Map()

  Object.keys(events).forEach((eventName) => {
    const listeners = currentEvents.get(eventName) ?? new Set()

    const callback = events[eventName]

    if (!listeners.has(callback)) {
      console.debug('Adding listener', { eventName, callback })
      listeners.add(callback)
      echoChannel.listen(eventName, callback)
    }

    currentEvents.set(eventName, listeners)
  })

  channelEvents.set(echoChannelName, currentEvents)
}

/**
 *
 * @param {Echo} echo
 * @param {string} channel
 * @param {Object<string, function>} events
 * @param {boolean} isPrivate
 */
const removeChannelEvents = (echo, channel, events, isPrivate) => {
  const echoChannel = getEchoChannel(echo, channel, isPrivate)

  console.info('useEcho', 'Removing events', { channel, events, isPrivate })

  const echoChannelName = echoChannel.name

  const currentEvents = channelEvents.get(echoChannelName) ?? new Map()

  Object.keys(events).forEach((eventName) => {
    const listeners = currentEvents.get(eventName) ?? new Set()

    const callback = events[eventName]

    if (listeners.has(callback)) {
      console.debug('Removing listener', { eventName, callback })
      listeners.delete(callback)
      echoChannel.stopListening(eventName, callback)
    }

    currentEvents.set(eventName, listeners)
  })

  channelEvents.set(echoChannelName, currentEvents)
}

/**
 *
 * @param {Echo} echo
 * @param {string} channel
 * @param {Object<string, function>} events
 * @param {boolean} isPrivate
 * @param {boolean} shouldCloseChannel
 */
const cleanupChannel = (echo, channel, events, isPrivate, shouldCloseChannel = true) => {
  removeChannelEvents(echo, channel, events, isPrivate)

  const echoChannel = getEchoChannel(echo, channel, isPrivate)
  const echoChannelName = echoChannel.name

  const currentEvents = channelEvents.get(echoChannelName) ?? new Map()

  if (shouldCloseChannel) {
    const listenersCount = Array.from(currentEvents.entries()).reduce((acc, [, listeners]) => {
      acc += listeners.size
      return acc
    }, 0)

    if (listenersCount === 0) {
      console.debug('Closing channel', { channel, isPrivate })
      leaveChannel(echo, channel, isPrivate)
    }
  }
}

/**
 *
 * @param {Echo} echo
 * @param {string} channel
 * @param {Object<string, function>} events
 * @param {boolean} isPrivate
 */
const useEcho = ({ channel, events = {}, isPrivate = true }) => {
  const echo = useContext(EchoContext)

  if (!echo) {
    throw new Error('useEcho must be used within an EchoProvider')
  }

  const connect = useCallback(() => {
    addChannelEvents(echo, channel, events, isPrivate)
  }, [channel, echo, events, isPrivate])

  const leave = useCallback(() => {
    cleanupChannel(echo, channel, events, isPrivate)
  }, [channel, echo, events, isPrivate])

  return { echo, connect, leave }
}

export default useEcho

import { useStore } from 'zustand'
import { createStore } from 'zustand/vanilla'

export const pagesStore = createStore((set, get) => ({
  cache: {},
  latestVisits: [],
  remember: (pageId, key, value) =>
    set((state) => {
      if (!state.cache[pageId]) {
        state.cache[pageId] = {}
      }

      state.cache[pageId][key] = value

      return state
    }),
  restore: (pageId, key) => {
    const cache = get().cache

    if (!cache[pageId]) {
      return null
    }

    return cache[pageId][key] ?? null
  },
}))

export const usePagesStore = (selector) => useStore(pagesStore, selector)

import clsx from 'clsx'
import { useState } from 'react'

const Alert = ({ children, className }) => {
  const [show, setShow] = useState(true)

  return (
    <>
      {show && (
        <div className={clsx('alert', className)}>
          <div className="container mx-auto">
            <div className="flex w-full flex-row space-x-4">
              <div className="flex-1">{children}</div>
              <button type="button" className="btn-ghost btn-xs btn" title="Cerrar" onClick={() => setShow(false)}>
                <i className="mdi mdi-close"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Alert

import clsx from 'clsx'
import { usePage } from '@inertiajs/react'
import ReactHtmlParser from 'html-react-parser'
import Alert from '@/Components/Common/Alert'
import config from '@/Lib/config'

const MessageBanner = () => {
  const { isNewUser } = usePage().props
  const { message, type } = usePage().props.banner ?? {}

  return (
    <div className="flex flex-col space-y-4">
      {!!isNewUser && (
        <Alert className="alert-success rounded-none shadow">
          <p>¡Gracias por registrarte! Tu cuenta debe ser aprobada por nuestro equipo.</p>
          <p>
            Podés comunicarte por{' '}
            <a className="link underline" href={config.whatsappUrl} target="_blank" rel="noreferrer">
              WhatsApp
            </a>{' '}
            para acelerar el proceso.
          </p>
        </Alert>
      )}
      {!!message && (
        <Alert className={clsx('rounded-none shadow', [`alert-${type ?? 'info'}`])}>
          <div>{ReactHtmlParser(message)}</div>
        </Alert>
      )}
    </div>
  )
}

export default MessageBanner

import { Link, usePage } from '@inertiajs/react'
import { useScroll } from '@use-gesture/react'
import clsx from 'clsx'
import React from 'react'
import { Button } from 'react-daisyui'
import { FaEllipsisH } from 'react-icons/fa'
import Drawer from 'react-modern-drawer'
import Logo from '@/Components/Common/Logo'

const Greeting = () => {
  const { auth, currentTeam } = usePage().props

  return (
    <div className="whitespace-nowrap">
      Hola, {auth.user.first_name}
      {!!currentTeam && <span> ({currentTeam.name})</span>}
    </div>
  )
}

const AuthLinks = ({ onNavigate = () => {} }) => {
  return (
    <>
      <li>
        <Link href={route('user.favorites')} onClick={onNavigate}>
          Favoritos
        </Link>
      </li>
      <li>
        <Link href={route('profile')} onClick={onNavigate}>
          Perfil
        </Link>
      </li>
      <li>
        <Link as="button" href={route('logout')} method="post" onClick={onNavigate}>
          Cerrar sesión
        </Link>
      </li>
    </>
  )
}

const UserLinks = ({ onNavigate = () => {} }) => {
  const { auth } = usePage().props

  return (
    <>
      {!!auth.user && (
        <>
          <div className="lg:hidden">
            <div className="p-4 text-center">
              <Greeting />
            </div>
            <ul className="menu">
              <AuthLinks onNavigate={onNavigate} />
            </ul>
          </div>
          <div className="hidden lg:block">
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="link no-underline">
                <Greeting />
              </label>
              <ul tabIndex={0} className="menu dropdown-content rounded-box w-52 bg-neutral p-2 shadow">
                <AuthLinks />
                <div className="divider my-0"></div>
                <TeamLinks onNavigate={onNavigate} />
              </ul>
            </div>
          </div>
        </>
      )}
      {!auth.user && (
        <ul className="menu">
          <li>
            <a href={route('login')}>Ingresar</a>
          </li>
        </ul>
      )}
    </>
  )
}

const TeamLinks = ({ onNavigate = () => {} }) => {
  const { currentTeam, teams } = usePage().props

  return (
    <ul className="menu">
      <li className="menu-title">
        <span>Sociedades</span>
      </li>
      <li className={clsx({ bordered: !currentTeam })}>
        <Link href={route('teams.reset')} method="post" as="button" onClick={onNavigate}>
          Personal
        </Link>
      </li>
      {React.Children.toArray(
        teams.map((team) => (
          <li className={clsx({ bordered: team.id === currentTeam?.id })}>
            <Link href={route('teams.switch', team.id)} method="post" as="button" onClick={onNavigate}>
              {team.name}
            </Link>
          </li>
        ))
      )}
    </ul>
  )
}

function NavLinks({ onNavigate = () => {} }) {
  return (
    <>
      <li>
        <a href="/#home" onClick={onNavigate}>
          Inicio
        </a>
      </li>
      <li>
        <a href="/#services" onClick={onNavigate}>
          Servicios
        </a>
      </li>
      <li>
        <a href="/#about" onClick={onNavigate}>
          Nosotros
        </a>
      </li>
      <li>
        <Link href={route('auctions.index')} onClick={onNavigate}>
          Subastas
        </Link>
      </li>
    </>
  )
}

const Header = ({ hideNav = false, logo = <Logo className="header-logo" /> }) => {
  // Drawer state
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  // Header ref
  const headerRef = React.useRef(null)

  // Scroll state
  useScroll(
    () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
    },
    { target: window }
  )

  // Toggle drawer
  const toggleDrawer = () => {
    // Prevent scrolling when drawer is open
    if (!drawerOpen) {
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
      const body = document.body
      body.style.position = 'fixed'
      body.style.top = `-${scrollY}`
    } else {
      const body = document.body
      const scrollY = body.style.top
      body.style.position = ''
      body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }

    setDrawerOpen(!drawerOpen)
  }

  const onNavigate = () => toggleDrawer()

  return (
    <>
      <Drawer open={drawerOpen} onClose={toggleDrawer} direction="right" className="overflow-y-auto" size="320px">
        <div className="bg-neutral p-4 text-neutral-content">
          <button className="btn btn-ghost btn-sm absolute left-1 top-1" onClick={toggleDrawer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <UserLinks onNavigate={onNavigate} />

          {/*<div className="divider my-0"></div>*/}

          {!hideNav && (
            <ul className="menu mt-6">
              <li className="menu-title">
                <span>Navegación</span>
              </li>
              <NavLinks onNavigate={onNavigate} />
            </ul>
          )}

          <div className="divider my-0"></div>
          <TeamLinks onNavigate={onNavigate} />
        </div>
      </Drawer>

      <header
        ref={headerRef}
        className="flex w-full flex-col justify-center bg-neutral py-4 text-neutral-content shadow-lg backdrop-blur"
      >
        <div className="container mx-auto flex items-center justify-between">
          <div>
            <Link href="/" className="navbar-logo block w-full text-primary">
              {logo}
            </Link>
          </div>

          {!hideNav && (
            <div className="hidden lg:block">
              <ul className="menu rounded-box menu-horizontal p-2">
                <NavLinks />
              </ul>
            </div>
          )}

          <div className="hidden w-[64px] lg:block">
            <UserLinks />
          </div>

          <div className="lg:hidden">
            <Button variant="ghost" onClick={toggleDrawer}>
              <FaEllipsisH className="h-6 w-6" />
            </Button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
